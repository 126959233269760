import React, { useState, useEffect, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { auth } from './api'
import './scss/style.scss'
import i18n from './i18n/i18n'
import jwt_decode from "jwt-decode";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  document.dir = i18n.dir();

  const isLoggedIn = () => {
    let token = JSON.parse(window.localStorage.getItem('token'));
    if(token){
      // lets decode the token
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        //console.log("Token expired.");
        // delete expired token and role
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("isAdmin");
        return false;
      } else {
        //console.log("Valid token");   
        return true;
      }
    }else{
      // token doesn't exist
      return false;
    }
  }

  return (
    <>
      {
        // dumb israeli programmer
        //errMessage ? <ShowMessage message={errMessage} /> : null
      }
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" element={isLoggedIn() ? <DefaultLayout /> : <Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  )
}

export default App
