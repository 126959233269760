import axios from 'axios';

const url = 'https://api.omniot.io/api';
//const url = 'http://127.0.0.1:1337/api';
const token = JSON.parse(window.localStorage.getItem('token'))

const auth = {
  login: (username,password) => {
    return null;
  },
  register: (username,password) => {
    return null;
  },
  logout: (token) => {
    return null;
  },
  me: (token) => {
    return null;
  }
};

const nodes = {

  updateNodeByID: async(node,data) => {
    let response = {}

    response =  await axios.post(`${url}/nodes/${node.id}`,data,{ headers: { Authorization: `Bearer ${token}`}})
    .then((res) => {return (res.data)})
    .catch((err) => {return ({"error":true,"message":err})});
    
    return response
  }
    
};

const sensors = {

  updateSensorByID: async(node,data) => {
    let response = {}

    response =  await axios.post(`${url}/nodes/sensors/${node.id}`,data,{ headers: { Authorization: `Bearer ${token}`}})
    .then((res) => {return (res.data)})
    .catch((err) => {return ({"error":true,"message":err})});
    
    return response
  },

  getSensorsByNodeID: async(nodeId) => {
    let response = {}

    response = await axios.get(`${url}/nodes/${nodeId}/sensors`, {headers: { Authorization: `Bearer ${token}`}})
    .then((res) => {return (res.data)})
    .catch((err) => {return ({"error":true,"message":err})});
    
    return response
  }
    
};

const locations = {
    
};

const simcardsAPI = {

  getSimCards:  async (offset,limit) => {
    let response = {}

    response =  await axios.get(`${url}/simcards?offset=${offset}&limit=${limit}`,{ headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {return (res.data)})
    .catch((err) => {return ({"error":true,"message":err})});
    
    return response
  }

}

const data = {

    getDataByRange:  async (nodeId, startDate, endDate) => {
        
      let today,yesterday,id;

      nodeId == undefined ? id = null : id = nodeId;
    
      startDate == undefined ? yesterday = Math.floor(Date.now() / 1000) : yesterday = startDate;
      endDate == undefined ? today = Math.floor(Date.now() / 1000) - 24 * 60 * 60 : today = endDate;

      let response = {}

      if(id != null){
          response =  await axios.get(`${url}/data/${id}/range?startDate=${yesterday}&endDate=${today}`,{ headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {return (res.data)})
          .catch((err) => {return ({"error":true,"message":err})});
      }
      return response
    }
};


export { auth, nodes, sensors, locations, data, simcardsAPI};